.shimmer {
    background: linear-gradient(90deg, #f0f0f000 25%, #7e5d5d00 50%, #f0f0f000 75%) !important;
  }
  
  .Shimmer_box {
    background-image: linear-gradient(to right, #27bade0a calc(50% - 100px), #0c010d28 50%, #de1cf80e calc(50% + 100px));
    background-size: 0;
    
    height: 175px; 
    position: relative;
    overflow: hidden;
    border-radius: 16px;
  }
  .Shimmer_box2 {
    background-image: linear-gradient(to right, #27bade0a calc(50% - 100px), #0c010d28 50%, #de1cf80e calc(50% + 100px));
    background-size: 0;
    
    height: 100%; 
    position: relative;
    overflow: hidden;
    border-radius: 16px;
  }
  
  .Shimmer_box::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: calc(200% + 200px);
    bottom: 0;
    background-image: inherit;
    animation: move 2s linear infinite;
  }
  .Shimmer_box2::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: calc(200% + 200px);
    bottom: 0;
    background-image: inherit;
    animation: move 2s linear infinite;
  }
  
  @keyframes move {
    to {
        transform: translateX(calc(50% + 100px));
    }
  }