.transactionHistory p {
    font-family: "Oswald", sans-serif;
}

.status_btn {
    border-radius: 4px;
    background: rgba(115, 197, 143, 0.20) !important;
    color: #fff;
}

.AccordionStyle .accordion-button::after {
    background-color: #23262F;
    background-position: center;
    border-radius: 20px;
    padding: 15px;
}

.AccordionStyle .accordion-button:not(.collapsed)::after {

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M21 8L11.5 17L2 8' stroke='%23777E90' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    transform: var(--bs-accordion-btn-icon-transform);
    background-size: 15px;
    background-position: center;
}

.accordion-button::after {

    background-size: 15px;
    transform: rotate(180deg);
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M21 17.0001L11.5 8.00012L2 17.0001' stroke='%23777E90' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

/* ----------------- */
body .niceModal .modal-content {
    border-radius: 30px;
    box-shadow: 0px 0px 0px rgb(80 26 104);
    padding: 0px;
    border: 1px solid #303030;
}

.niceModal .modal-header {
    border-bottom: 1px solid #ccc !important;
    padding: 0px;
}

.niceModal .btn-close {
    background-size: 14px;
}

.dashboard_home .box {

    border-radius: 24px;
    background: rgb(23 25 28 / 81%);
    padding: 35px 20px;
    /* height: 100%; */
    position: relative;
}

.dashimg {
    height: 45px;
    width: auto;
    object-fit: contain;
}


.invest-gold-card {
    background: linear-gradient(#131313, #131313) padding-box,
        linear-gradient(142deg, rgba(252, 192, 59, 1) 0%, rgba(16, 16, 16, 1) 16%, rgba(16, 16, 16, 1) 89%, rgba(252, 192, 59, 1) 100%) border-box !important;
    border-radius: 16px;
    border: 1px solid transparent;
    cursor: pointer;
}

.invest-platinum-card {
    background: linear-gradient(#131313, #131313) padding-box,
        linear-gradient(142deg, rgba(222, 224, 226, 1) 0%, rgba(16, 16, 16, 1) 16%, rgba(16, 16, 16, 1) 89%, rgba(222, 224, 226, 1) 100%) border-box !important;
    border-radius: 16px;
    border: 1px solid transparent;
    cursor: pointer;
}
.invest-diamond-card {
    background: linear-gradient(#131313, #131313) padding-box,
    linear-gradient(142deg, rgba(80,150,233,1) 0%, rgba(16,16,16,1) 16%, rgba(16,16,16,1) 89%, rgba(80,150,233,1) 100%) border-box !important;
    border-radius: 16px;
border: 1px solid transparent;
cursor: pointer;
}

.gold-button {
    border-radius: 9.052px;
    border: none;
    height: 50px;
    color: black;
    font-weight: bold;
    background: linear-gradient(94deg, #C3A333 3.19%, #C49742 38.79%, #FFC453 55.99%, #FFD88E 66.84%, #FFDB96 82.63%, #C3A333 91.13%);
}

.platinum-button {
    border-radius: 9.052px;
    border: none;
    height: 50px;
    color: black;
    font-weight: bold;
    background: linear-gradient(93deg, #B0B5B6 4.19%, #9C9C9C 27.83%, #DEE0E2 51.47%, #E6E8EC 75.12%, #787878 98.76%);
}

.invest-details-gold {
    border-radius: 16px;
    border: 1px solid var(--stroke, #00F6F6);
    background: linear-gradient(90deg, rgba(249, 7, 252, 0.10) 0%, rgba(0, 246, 246, 0.10) 100%);
    padding: 32px;
}
.invest-details-platinum {
    border-radius: 16px;
    border: 1px solid var(--stroke, #00F6F6);
    background: linear-gradient(90deg, rgba(249, 7, 252, 0.10) 0%, rgba(0, 246, 246, 0.10) 100%);
    padding: 32px;
}
.invest-details-diamond {
    border-radius: 16px;
    border: 1px solid var(--stroke, #00F6F6);
    background: linear-gradient(90deg, rgba(249, 7, 252, 0.10) 0%, rgba(0, 246, 246, 0.10) 100%);
    padding: 32px;
}

.invest-details-gold-card {
    background: linear-gradient(#131313, #131313) padding-box,
        linear-gradient(142deg, rgba(252, 192, 59, 1) 0%, rgba(16, 16, 16, 1) 16%, rgba(16, 16, 16, 1) 89%, rgba(252, 192, 59, 1) 100%) border-box !important;
    border-radius: 16px;
    border: 1px solid transparent;
    cursor: pointer;
}

.invest-details-platinum-card {
    background: linear-gradient(#131313, #131313) padding-box,
        linear-gradient(142deg, rgba(222, 224, 226, 1) 0%, rgba(16, 16, 16, 1) 16%, rgba(16, 16, 16, 1) 89%, rgba(222, 224, 226, 1) 100%) border-box !important;
    border-radius: 16px;
    border: 1px solid transparent;
    cursor: pointer;
}
.invest-details-diamond-card {
    background: linear-gradient(#131313, #131313) padding-box,
    linear-gradient(142deg, rgba(80,150,233,1) 0%, rgba(16,16,16,1) 16%, rgba(16,16,16,1) 89%, rgba(80,150,233,1) 100%) border-box !important;
    border-radius: 16px;
border: 1px solid transparent;
cursor: pointer;
}

.invest-gold {
    background: linear-gradient(95deg, #C3A333 4.04%, #C49742 20.48%, #E3CEA6 31.37%, #FFF 41.49%, #C3A333 53.38%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.invest-platinum {
    background: var(--Plans-Platinum, linear-gradient(93deg, #B0B5B6 4.19%, #9C9C9C 27.83%, #DEE0E2 51.47%, #E6E8EC 75.12%, #787878 98.76%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.invest-diamond {
   
    background: linear-gradient(90deg, #498EE9 0%, #A0DBFB 30.5%, #76B3F2 57%, #478FE7 84%, #498EE9 100%);
    background-clip: text;
   
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.invest-gold-price {
    background: linear-gradient(95deg, #C3A333 4.04%, #C49742 20.48%, #E3CEA6 31.37%, #FFF 41.49%, #C3A333 53.38%);
    background-clip: text;
    width: fit-content;
    height: fit-content;
    -webkit-background-clip: text;
    margin-bottom: 0px;
    -webkit-text-fill-color: transparent;

    font-size: 80px;
    font-weight: 700;
}

.invest-platinum-price {
    background: linear-gradient(93deg, #B0B5B6 4.19%, #9C9C9C 27.83%, #DEE0E2 51.47%, #E6E8EC 75.12%, #787878 98.76%);
    background-clip: text;

    height: fit-content;
    margin-bottom: 0px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-size: 80px;
    font-weight: 700;
}
.invest-diamond-price {
    background: linear-gradient(90deg, #498EE9 0%, #A0DBFB 30.5%, #76B3F2 57%, #478FE7 84%, #498EE9 100%);
    background-clip: text;

    height: fit-content;
    margin-bottom: 0px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-size: 80px;
    font-weight: 700;
}

.invetsment-details-ul li {
    color: #F3F3F3;
    font-family: "Acumin Pro";
    font-size: 16px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 32px */
}

.include-gold-h3 {
    background: linear-gradient(95deg, #C3A333 4.04%, #ffffff 10.48%, #fcb22b 25.37%, #FFF 20.49%, #C3A333 53.38%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.include-platinum-h3 {
    background: var(--Plans-Platinum, linear-gradient(93deg, #B0B5B6 4.19%, #9C9C9C 10.83%, #DEE0E2 25.47%, #E6E8EC 20.12%, #787878 98.76%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.include-diamond-h3 {
    background: linear-gradient(90deg, #498EE9 0%, #A0DBFB 30.5%, #76B3F2 57%, #478FE7 84%, #498EE9 100%);
   
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.invest-usdt {
    width: 40px;

}

.invest-gold-img{
    width: 64px;
    height: 64px
}
.vest{
    border-radius: 16px 16px 0px 0px;
}
.vesting-row{
    background-color:rgba(102, 104, 206, 0.303);
   width: 100%;
}
.vesting-row h5{
    margin-bottom: 0px;
}


@media screen and (max-width: 1200px) {
    .goldCard h1 {
        font-size: 28px;
    }
}

@media screen and (min-device-width: 200px) and (max-device-width: 800px) {
    p {
        font-size: 12px;
    }

    .dashboard_home .box {
        border: 1px solid #303030;
        border-radius: 24px;
        background: rgb(23 25 28 / 81%);
        padding: 35px 15px;
        /* height: 100%; */
        position: relative;
    }

    .invest-platinum-price {
        font-size: 40px;
    }
    .invest-gold-price {
        font-size: 40px;
    }
}

@media only screen and (max-width: 500px) {
    .AccordionStyle .accordion-item .accordion-button {
        padding-left: 0px;
        display: block;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 380px) {
    .cardline {
        display: block !important;
    }
    .hd{
        font-size: calc(1rem + .9vw);
    }
    .invest-gold-img{
        width: 30px;
        height: 30px
    }
    .dashboard-h2{
        font-size: 16px;
    }
}