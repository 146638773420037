.dashcard{
    border-radius: 16px;

    background: linear-gradient(90deg, rgba(249, 7, 252, 0.10) 0%, rgba(0, 246, 246, 0.10) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    gap: 20px;
}

.dashboard-h2{
    color: #FFF;
text-align: center;
-webkit-text-stroke-width: 1;
-webkit-text-stroke-color: #000;
font-family: Unbounded;
font-size: 24px;
font-style: normal;
font-weight: 900;
}
.dashboard-h2 span{
    color: #FFF;
font-family: Unbounded;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%;
letter-spacing: 1px;
}

.dashboard-p{
    color: #F3F3F3;
text-align: center;
font-family: "Acumin Pro";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
letter-spacing: 1px;
margin-bottom: 0px;
}
.dashboard-p span{
    color: #14AE5C;
}

.dashboard-option{
    transition: all 1s ease-in-out;
    color: #FFF;
text-align: center;
font-family: "Acumin Pro";
font-size: 16px;
font-style: normal;

line-height: 120%; /* 19.2px */
letter-spacing: 0.32px;
text-transform: uppercase;
}


@media (max-width:1400px){
    .dashcard{
        height: 250px;
    }
    .dashboard-h2{
      
    font-size: 18px;
   
    } 
    .dashboard-p{
font-size: 13px;
    }
}