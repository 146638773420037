.fix ul {
    list-style: none;
    padding-left: 0px;
}
.fix ul li{
   margin-bottom: 10px;
   color: #C9C9C9;
   /* font-family: "Oswald", sans-serif; */
}
.include-list span{
    color: #FFF;
font-family: "Acumin Pro";
font-size: 18px;
font-style: normal;
font-weight: 700;
}
.dashboard-option{
    cursor: pointer;
}
.include-h3{
    font-family: Unbounded;
}

.text-grey {
    color: rgba(255, 255, 255, 0.50)!important;
}
/* ------------- */
.gold .text-gradient {
    background: var(--Plans-Gold, linear-gradient(95deg, #C3A333 4.04%, #C49742 20.48%, #E3CEA6 31.37%, #FFF 41.49%, #C3A333 53.38%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-gradient-gold {
    background: linear-gradient(95deg, #C3A333 4.04%, #C49742 20.48%, #E3CEA6 31.37%, #FFF 41.49%, #C3A333 53.38%) ;
    background-clip: text;
    width: fit-content;
    height:fit-content;
    -webkit-background-clip: text;
    margin-bottom: 0px;
    -webkit-text-fill-color: transparent;
   
    font-size: 60px;
font-weight: 700;
}

.card-border{
    background: linear-gradient(#131313, #131313) padding-box,
   linear-gradient(142deg, rgba(252,192,59,1) 0%, rgba(16,16,16,1) 16%, rgba(16,16,16,1) 89%, rgba(252,192,59,1) 100%) border-box !important;
border-radius: 40px;
border: 1px solid transparent;
cursor: pointer;
}
.card-border-platinum{
    background: linear-gradient(#131313, #131313) padding-box,
    linear-gradient(142deg, rgba(222,224,226,1) 0%, rgba(16,16,16,1) 16%, rgba(16,16,16,1) 89%, rgba(222,224,226,1) 100%) border-box !important;
border-radius: 40px;
border: 1px solid transparent;
cursor: pointer;
}
.card-border-diamond{
    background: linear-gradient(#131313, #131313) padding-box,
    linear-gradient(142deg, rgba(80,150,233,1) 0%, rgba(16,16,16,1) 16%, rgba(16,16,16,1) 89%, rgba(80,150,233,1) 100%) border-box !important;
border-radius: 40px;
border: 1px solid transparent;
cursor: pointer;
}

.dashboard_home .box.gold.active {
    border: 1px solid #C3A333;
}
.gold button {
    border-radius: 8px;
    background: linear-gradient(94deg, #C3A333 3.19%, #C49742 38.79%, #FFC453 55.99%, #FFD88E 66.84%, #FFDB96 82.63%, #C3A333 91.13%);
    border: none;
    color: var(--Card-BG, #110F11)!important;
    font-weight: 700;
    line-height: normal;
    height: 45px;
}
/* ------------- */
.platinum .text-gradient {
    background: var(--Plans-Platinum, linear-gradient(93deg, #B0B5B6 4.19%, #9C9C9C 27.83%, #DEE0E2 51.47%, #E6E8EC 75.12%, #787878 98.76%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.text-gradient-platinum {
    background: linear-gradient(93deg, #B0B5B6 4.19%, #9C9C9C 27.83%, #DEE0E2 51.47%, #E6E8EC 75.12%, #787878 98.76%);
    background-clip: text;
    
    height:fit-content;
    margin-bottom: 0px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
   
    font-size: 60px;
font-weight: 700;
}
.dashboard_home .box.platinum.active {
    border: 1px solid #B0B5B6;
}
.platinum button {
    border-radius: 8px;
    background: var(--Plans-Platinum, linear-gradient(93deg, #B0B5B6 4.19%, #9C9C9C 27.83%, #DEE0E2 51.47%, #E6E8EC 75.12%, #787878 98.76%));
    border: none;
    color: var(--Card-BG, #110F11)!important;
    font-weight: 700;
    line-height: normal;
    height: 45px;
}
/* --------------- */
.diamond .text-gradient {
    background: var(--Plans-Diamond, linear-gradient(90deg, #498EE9 0%, #A0DBFB 30.5%, #76B3F2 57%, #478FE7 84%, #498EE9 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.diamond .text-gradient-diamond {
    background: linear-gradient(90deg, #498EE9 0%, #A0DBFB 30.5%, #76B3F2 57%, #478FE7 84%, #498EE9 100%);
    background-clip: text;
    width: fit-content;
    height:fit-content;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    margin-bottom: 0px;
font-weight: 700;
}
.dashboard_home .box.diamond.active {
    border: 1px solid #498EE9;
}
.diamond button {
    border-radius: 8px;
    background: var(--Plans-Diamond, linear-gradient(90deg, #498EE9 0%, #A0DBFB 30.5%, #76B3F2 57%, #478FE7 84%, #498EE9 100%));
    border: none;
    color: var(--Card-BG, #110F11)!important;
    font-weight: 700;
    line-height: normal;
    height: 45px;
}
/* -------------- */
.dashboard_home .box.platinum {
    border-radius: 24px;
    background: #17191C;
}


.dashboard_home .box.platinum {
    border-radius: 24px;
    border: 1px solid rgba(215, 215, 215, 0.20);
    background: #17191C;
}
/* ------------------------- */
.card_bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
}
/* ----------------- */
.tableStyle {
    border-radius: 16px;
}
.AccordionStyle .accordion-item .accordion-button {
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.AccordionStyle .accordion-body{
    padding-left: 0px;
    padding-right: 0px;
}

.fix{
    

background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 74.6%, rgba(153, 153, 153, 0.00) 95.52%) !important;
backdrop-filter: blur(2.5px) !important;
border-radius: 24px;
}

.modal-text-grad{
    background: linear-gradient(90deg, #498EE9 0%, #A0DBFB 30.5%, #76B3F2 57%, #478FE7 84%, #498EE9 100%);
    background-clip: text;
    width: fit-content;
    height:fit-content;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
   
    margin-bottom: 0px;
font-weight: 700;
}
.modal-gold-grad{
    background: linear-gradient(95deg, #C3A333 4.04%, #C49742 20.48%, #E3CEA6 31.37%, #FFF 41.49%, #C3A333 53.38%) ;
    background-clip: text;
    width: fit-content;
    height:fit-content;
    -webkit-background-clip: text;
    margin-bottom: 0px;
    -webkit-text-fill-color: transparent;
font-weight: 700;
}
.modal-platinum-grad{
    background: linear-gradient(93deg, #B0B5B6 4.19%, #9C9C9C 27.83%, #DEE0E2 51.47%, #E6E8EC 75.12%, #787878 98.76%);
    background-clip: text;
    
    height:fit-content;
    margin-bottom: 0px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
   
    
font-weight: 700;
}
.bnt_pri_uni{
   
    border: 1px solid #00000091 !important;
    border-radius: 8px;
    color: #fff;
    font-family: Oswald, sans-serif !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 10px 19px;
    text-decoration: none !important;
    text-transform: uppercase;
}
@media (max-width:540px){
    .platinum .text-gradient-platinum , .gold .text-gradient-gold , .diamond .text-gradient-diamond {
        font-size: 50px;
    }
}