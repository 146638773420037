.dashboard {
    background: black;
}

a.ps-menu-button.ps-active{

    color: white !important;
    border-radius: 0px 4px 4px 0px;
    margin-right: 20px;
}

li.ps-menuitem-root.css-kh81go :active{

    color: white !important;
    border-radius: 0px 4px 4px 0px;
}


#outlet {
    height: 100vh;
    overflow-y: scroll;
   
}

.sidebar {
    height: 100vh;
    background: rgba(23, 25, 28, 0);
    position: fixed !important;
    top: 0;
    z-index: 9;
    width: 250px;
    border-right: 1px solid #3c4042 !important;
}

.logOut {
    position: fixed;
    bottom: 10px;
    /* z-index: 15; */
    color: white;
    font-family: "Oswald", sans-serif;
    font-size: 20px;
    left: 32px;
    text-align: center;
}

.css-dip3t8 {
    border-right: 1px solid rgba(185, 185, 185, 0.20);
    background: transparent !important;
    backdrop-filter: blur(25px);
}

li.ps-menuitem-root {
    position: relative;
}

li.ps-menuitem-root.ps-active.css-qyrmmo::after {
    content: '';
   
background: var(--Brand-Gradient, linear-gradient(90deg, #05D6D9 0.3%, #F907FC 99.65%)) !important;
    position: absolute;
    height: 49px;
    border-radius: 4px 0px 0px 4px;
    width: 5px;
    right: 0px;
    top: 0px;

}

.sidebar_header {
    padding: 13px 20px;
   
    height: 60px;
    /* text-align: center; */
    margin-bottom: 20px;
    margin-left: 25px;
    padding: 20px 0px;
}

.sidebar_header img {
    height: 40px;
    width: auto;
}

.sidebar_header2 {
    height: 55px;
    text-align: center;
    margin-top: 5px;
}

.sidebar_header2 img {
    height: 30px;
    width: auto;
}

a.ps-menu-button {
    background: none;
    margin-bottom: 10px;
    padding: 20px 0px;
}
.css-qyrmmo >.ps-menu-button{
    background: linear-gradient(to right, #13c9da,
    #7b73ea, #b641f3, #e11af8) !important;
}
a.ps-menu-button:hover {
    background: linear-gradient(to right, #13c9da,
    #7b73ea, #b641f3, #e11af8) !important;
    color: rgb(255, 255, 255) !important;
    margin-right: 20px;
    border-radius: 0px 4px 4px 0px;
 
}

span.ps-menu-label.css-12w9als {
    font-family: "Oswald", sans-serif;
}

span.ps-menu-icon.css-wx7wi4 {
    font-size: 20px;
}

.marginL250 {
    margin-left: 250px;
    transition: 0.3s;
}

.marginL25 {
    margin-left: 50px;
    transition: 0.3s;
}

/* @media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .marginL250 {
        width: 0px !important;
    }
} */
@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .css-kh81go >.ps-menu-button{
        padding: 0px 5px !important;
    }
    .css-qyrmmo >.ps-menu-button{
        padding-left: 0px  !important;
    }
    .css-1wvake5.ps-collapsed{
        width: 50px !important;
        min-width: 50px !important;
    }
    a.ps-menu-button.ps-active{
        margin-right: 10px !important;
    }
    
}