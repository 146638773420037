.slotdetail ul li {
    color: #fff;
    padding-left: 0px;
}
.slotdetail .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg);
    background: linear-gradient(to right, #05D6D9, #F907FC) border-box !important;
}
.slotdetail .nav-pills .nav-link {
    padding: 5px 10px!important;
}
.slotdetail .nav-pills .nav-link {
    padding: 9px 30px!important;
    margin-right: 20px;
    margin-bottom: 0px;
}
.niceTable .table>:not(caption)>*>* {
    --bs-table-bg:transparent;
    --bs-table-color: #fff;
    padding: 0.7rem .5rem;
}
.niceTable .table-hover>tbody>tr:hover>* {
    --bs-table-hover-color:#fff;
}
.bg-gradient-danger {
    background:linear-gradient(94deg, #C3A333 3.19%, #C49742 38.79%, #FFC453 55.99%, #FFD88E 66.84%, #FFDB96 82.63%, #C3A333 91.13%)!important;
}
.goldCard{
    box-shadow: none!important;
}
.goldCard h4,.goldCard h1,.goldCard h5 {
    font-family: "Oswald", sans-serif;
    color: #fff!important;
}
.goldCard .bg-img {
    position: absolute;
    right: 0;
    height: 100%;
    bottom: 0;
    z-index: 0;
    top: 0;
}
