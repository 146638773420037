.product-main{
   
background: linear-gradient(#131313, #131313) padding-box,
linear-gradient(142deg, rgba(0,246,246,1) 0%, #131313 16%, #131313 89%, rgba(0,246,246,1) 100%) border-box;
border-radius: 40px;
border: 1px solid transparent;
}

.product-main p{
    color: #FFF;
font-family: "Acumin Pro";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 32px */
letter-spacing: 0.4px;
}

.product-main .our-products{
    background: linear-gradient(90deg, rgba(249, 7, 252, 0.10) 0%, rgba(0, 246, 246, 0.10) 100%);

}
.p-small{
    color: rgba(255, 255, 255, 0.50) !important;
font-family: "Acumin Pro";
font-size: 14px !important;
font-style: normal;
font-weight: 400 !important;
line-height: 100%; /* 16.096px */
}
