.btn_uni {
    background: linear-gradient(90deg, #1a2424, #2b1c2b) padding-box, linear-gradient(90deg, #05d6d9, #f907fc) border-box;
    border: 1px solid #00000091 !important;
    border-radius: 8px;
    color: #fff;
    font-family: Oswald, sans-serif !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 10px 19px;
    text-decoration: none !important;
    text-transform: uppercase;
    transition: all .9s ease-out;
    transition: .9s;
}

/* .our_features,
.why_presale,
.how_it_works,
.our_packages,
.faq {
  padding: 3% 0px;
} */

a {
    color: #fff !important;
    text-decoration: none !important;
}

