.hb{
    /* position: relative; */
    background-image: url(../../../../../../public/image/transparent01.png);
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
  
    background-position:  -150px;
 
  
}
.m-back{
    position: absolute;
    z-index: -3;
}

.heading_b {
 
    border: 1px solid #6e6e6ec9;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600 !important;
    margin-bottom: 2px;
    padding: 5px 16px;
}

.banner_content {
    padding: 16% 0px;
}

.banner_mask {
   
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: #121314;
    z-index: -1;
    opacity: 0.8;
}

.main-header {
    color: #E1E1E1;
font-family: Proxima NovaEB;
font-size: 70px;
font-style: normal;
font-weight: 900;
line-height: 80px; /* 133.333% */
letter-spacing: 2.4px;
width:100%;
}

.m-stake{
    font-family: "Proxima Nova";
    margin-bottom: 0px;
}

.banner_content h1 span{
    background: linear-gradient(270deg, #02F4F6 33.18%, #F60AFC 99.54%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Proxima NovaEB;
font-size: 70px;
font-style: normal;
font-weight: 900;
line-height: 80px;
letter-spacing: 2.4px;
}

.m-para {
    color: #FFF;
    text-shadow: 2px 2px 12px #000;
    font-family: "Acumin Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 32px */
    letter-spacing: 0.4px;
    width: 70%;
    margin-bottom: 0px;
}


.btn_uni2{
    background-color: #02F4F6;
    border: none;
    text-transform: uppercase;
    color: #000;
    font-weight: 500;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.btn_uni2:hover{
    background: linear-gradient(270deg, #02f6f68e 33.18%, #f80afc88 99.54%);
    color: #ffffff;
}
/* .home_banner .content{
    display: flex;
    align-items: center;
} */

.triangle-down {
    /* transform: rotate(90deg);
    border-left: 0 solid #0000;
    border-right: 24px solid #0000;
    border-top: 24px solid #171f09;
    height: 0;
    top: -1px;
    position: absolute;
    width: 0;
    right: -1px; */
}
.banner-right{
    height: 350px;
}
.banner-coin{
    top: 50%;
    left:50%;
    transform: translate(-0%, -50%);
}
.banner-price{
    background-color: #1F1F1F;
    top: 55%;
    left:25%;
    transform: translate(-50%, -50%);
}
.primary-button-investor{
    display: flex;
width: fit-content;
/* height: 48px; */
padding: 8px 16px;
justify-content: center;
align-items: center;
border: none;
gap: 10px;
    border-radius: 8px;
background: linear-gradient(90deg, #F907FC 0%, #00F6F6 100%);
}

.primary-button-investor:hover{
    background: transparent;
    border: 1px solid #F907FC;
}

.slide-top {
	-webkit-animation: slide-top 2s ease-in-out infinite alternate both;
	        animation: slide-top 2s ease-in-out infinite alternate both;
}

@-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
@media  (max-width: 400px){
    .main-header{
        font-size: 30px;
        line-height: 40px; /* 133.333% */
        letter-spacing: 1.8px;
    }
    .banner_content h1 span{
font-size: 30px;
line-height: 40px; /* 133.333% */
letter-spacing: 1.8px;
    }
}
@media screen and (min-device-width: 400px) and (max-device-width: 768px){
   
    .home_banner{
        background-size: cover;
    }
    .banner_content p {
        font-size: 18px;
    }
    .banner_content {
        padding: 20% 0px;
    }
    .main-header{
        font-size: 35px;
        line-height: 50px; /* 133.333% */
        letter-spacing: 1.8px;
        width: 90%;
    }
    .banner_content h1 span{
font-size: 35px;
line-height: 50px; /* 133.333% */
letter-spacing: 1.8px;
    }
    
    
}

@media (max-width: 768px){
    .main-header{
        font-size: 35px;
        line-height: 50px; /* 133.333% */
        letter-spacing: 1.8px;
        width: 100%;
    }
    .m-para{
        width: 100%;
    }
}
@media screen and (min-device-width: 769px) and (max-device-width: 1025px){
   
    .home_banner{
        background-size: cover;
    }
    .banner_content p {
        font-size: 18px;
    }
    .banner_content {
        padding: 20% 0px;
    }
    .main-header{
        font-size: 45px;
        line-height: 60px; /* 133.333% */
        letter-spacing: 1.8px;
    }
    .banner_content h1 span{
font-size: 45px;
line-height: 60px; /* 133.333% */
letter-spacing: 1.8px;
    }
    
}

@media (max-width: 1000px ){
    .banner-coin{
        top: 50%;
        left:50%;
        transform: translate(-50%, -50%);
    }
    .banner-price{
        background-color: #1f1f1fc7;
        top: 55%;
        left:15%;
        transform: translate(-50%, -50%);
    }
    .banner-right{
        height: 250px;
    }
}