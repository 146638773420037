.how_it_works_banner {
  /* background-image: url(../../../../../../public/image/bannerbackground/banner6.png);
    background-repeat: no-repeat;
    background-size: 100% 100%; */
  position: relative;
  background-position: center;
  z-index: 0;
}

.how_it_works .btn_hiw a {
  text-decoration: none !important;
  color: rgba(255, 255, 255, 1);
}

.how_it_works .btn_hiw {
  border-bottom: 1px solid #727272;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.how_it_works .btn_hiw .icon {
  color: white;
}

.how_it_works h3 {
  /* font-size: 18px; */
  font-size: 25px;
}

.how_it_works .choose_game p {
  /* font-size: 13px; */
  font-size: 15px;
}

.how_it_works .v_box {
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.how_it_works .v_box iframe {
  border-radius: 20px;
  max-width: 100%;
}

.how_it_works .v_box .ply_btn {
  color: #f34e4e;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

.how_it_works .col-md-3 {
  display: flex;
  align-items: center;
}
.hiw-points {
  padding: 30px 0px;

}
.box:hover {
  box-shadow: 0 0 14px 8px #211d21;
  transition: 0.5s;
}
@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
  .how_it_works p {
    margin-bottom: 0px;
  }
  .how_it_works_banner {
    background-attachment: fixed;
    background-size: cover;
  }
 
}

.accordion-button {
  border-radius: 16px;
  border: 1px solid var(--stroke, #00f6f6);
  background: #131313;
  margin-bottom: 20px;
}
.accordion-item {
  border-radius: 16px !important;
  background: #131313;
}

.accordion-item:first-of-type > .accordion-header .accordion-button {
 
  border-radius: 16px;
}
.accordion-item .show .accordion-body{
       background: linear-gradient(#131313, #131313) padding-box, linear-gradient(152deg, rgba(0, 246, 246, 0) 0%, #131313 16%, #131313 89%, rgba(0, 246, 246, 1) 100%) border-box !important;
       border-radius: 16px;
       border: 1px solid transparent;
}
.accordion-body,
button.accordion-button.collapsed {
  background: linear-gradient(#131313, #131313) padding-box,
    linear-gradient(
        152deg,
        rgb(0, 246, 246) 0%,
        #131313 16%,
        #131313 89%,
        rgba(0, 246, 246, 1) 100%
      )
      border-box !important;
  border-radius: 16px;
  border: 1px solid transparent;
  margin-bottom: 20px;
}



.accordion-item:first-of-type {
  border-radius: 16px !important;
}

/* LAST accord */

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
    background: linear-gradient(#131313, #131313) padding-box,
      linear-gradient(
          152deg,
          rgb(0, 246, 246) 0%,
          #131313 16%,
          #131313 89%,
          rgba(0, 246, 246, 1) 100%
        )
        border-box !important;
    border-radius: 16px;
    border: 1px solid transparent;
    margin-bottom: 20px;
  }
.accordion-button:not(.collapsed) {
  background: linear-gradient(#131313, #131313) padding-box,
    linear-gradient(
        159deg,
        rgb(0, 246, 246) 0%,
        #131313 16%,
        #131313 89%,
        rgba(0, 246, 246, 0) 100%
      )
      border-box !important;
  border-radius: 16px;
  border: 1px solid transparent;
  margin-bottom: 0px;
}
.accordion-item:last-of-type > .accordion-button:not(.collapsed) {
    background: linear-gradient(#131313, #131313) padding-box,
      linear-gradient(
          159deg,
          rgb(0, 246, 246) 0%,
          #131313 16%,
          #131313 89%,
          rgba(0, 246, 246, 0) 100%
        )
        border-box !important;
    border-radius: 16px;
    border: 1px solid transparent;
    margin-bottom: 0px;
  }
.accordion-item:first-of-type > .accordion-button:not(.collapsed) {
    background: linear-gradient(#131313, #131313) padding-box,
      linear-gradient(
          159deg,
          rgb(0, 246, 246) 0%,
          #131313 16%,
          #131313 89%,
          rgba(0, 246, 246, 0) 100%
        )
        border-box !important;
    border-radius: 16px;
    border: 1px solid transparent;
    margin-bottom: 0px;
  }
.accord-data {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Acumin Pro";
  font-size: 14px;
  font-weight: 400;
}
.accord-head {
  font-family: Unbounded;
}
