.profile_updation {
    margin: 5% 0px;
    border: 1px solid rgba(239, 239, 239, 0.1);
    background: rgba(23, 25, 28, 1);
    padding: 2% 1%;
    border-radius: 15px;
}

.profile_updation .img_box {
    background: rgba(34, 38, 48, 1);
}

.profile_updation .img_wrapper {
    /* margin-bottom: 20%; */
    padding: 10px;
}

.img_box {
    border-radius: 15px;
    padding: 35px;
}

.profile_updation .btn_wrapper {
    text-align: center;
    margin: 20px;
}

.profile_updation .btn_wrapper button {
    width: 100%;
    padding: 0px !important;
}

.profile_updation .btn_wrapper .btn_uni2 {
    padding: 10px 0px !important;
}

.profile_updation .btn_wrapper .btn_uni div {
    background: #2a263a !important;
    padding: 10px 0px;
    border-radius: 7px;
}

.profile_updation .img_wrapper span {
    bottom: 40px;
    right: -10px;
    font-weight: 500;
    font-size: 20px;
    color: white;
    background: rgba(11, 209, 217, 1);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.field_wrapper {
    margin-top: 12px;
}

.profile_updation .imgBox .img-fluid {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: contain;
}

#upload_profile_img {
    position: absolute;
    z-index: 2;
    bottom: 40px;
    width: 45px;
    right: -7px;
    opacity: .01;
    height: 45px;
    border-radius: 50%;
}

@media screen and (min-device-width: 200px) and (max-device-width: 968px) {
    .img_box {
        border-radius: 32px;
        padding: 5px;
    }

    .field_wrapper {
        margin-top: 6px;
    }
}