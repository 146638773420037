a.nav-link {
    font-weight: 300;
    padding: 0px !important;
    font-size: 18px;
    font-family: "Oswald", sans-serif;
}
.middle-nav .nav-link.active {
    font-weight: bold;
    color: #fc3eff !important;
  }
.header_section {
    position: sticky;
    top: 0px;
    z-index: 2000;
    width: 100%;
    background: rgba(255, 255, 255, 0.05) !important;
backdrop-filter: blur(25px) !important;
}

button.order-2.order-md-3.order-md-1.navbar-toggler {
    font-size: 12px;
}
.cool-link {
    position: relative;
    text-decoration: none;
    color: black; /* Adjust as needed */
    padding: 5px; /* Adjust as needed */
  }
  
  .cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: linear-gradient(to right, #05D6D9, #F907FC);
    transition: width .3s;
    cursor: pointer;
  }
  
  .cool-link:hover::after {
    width: 100%;
  }
  
  .cool-link.active::after {
    width: 100%;
  }
  
.cool-link:active::after {
    width: 100%;
    /* //transition: width .3s; */
}

header .navbar-nav .nav-link:hover::after {
    width: 100%;
}

.header .btn_uni {
    padding: 7px 20px;
}

button.order-2.order-md-1.order-md-1.navbar-toggler.collapsed {
    outline: none;
}

.navbar-brand img {
    padding: 5px 0px;
    height: 40px;
}

.me-auto.justify-content-center.navbar-nav {
    width: 100%;
}

a.btn_uni.nav-link {
    color: white;
}

.modal-content {
    background: rgba(255, 255, 255, 0.05) !important;
    backdrop-filter: blur(25px) !important;
    color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 2px #48484848;
    padding: 15px;
}
.gold-modal .modal-dialog .modal-content{
    background: linear-gradient(#131313, #131313) padding-box,
    linear-gradient(142deg, rgba(252,192,59,1) 0%, rgba(16,16,16,1) 16%, rgba(16,16,16,1) 89%, rgba(252,192,59,1) 100%) border-box !important;
 border-radius: 16px;
 border: 1px solid transparent;
 cursor: pointer;
}
.platinum-modal .modal-dialog .modal-content{
    background: linear-gradient(#131313, #131313) padding-box,
    linear-gradient(142deg, rgba(222,224,226,1) 0%, rgba(16,16,16,1) 16%, rgba(16,16,16,1) 89%, rgba(222,224,226,1) 100%) border-box !important;
border-radius: 16px;
border: 1px solid transparent;
cursor: pointer;
}

.modal h4 {
    color: #d5d5d5;
}

.modal .btn_uni {
    background: linear-gradient(to right, #1a2424, #2b1c2b) padding-box,
        linear-gradient(to right, #05D6D9, #F907FC) border-box;
    border-radius: 8px;
    font-weight: 400 !important;
    /* border: 1px solid transparent !important; */
    border: 1px solid #00000091 !important;
    font-size: 15px !important;
    text-decoration: none !important;
    font-family: "Oswald", sans-serif !important;
    color: white;
    padding: 10px 19px;
    text-transform: uppercase;
    transition: all .9s ease-out;
    transition: 0.9s;
}

.btn-close:focus {
    box-shadow: none !important;
}

.modal .btn-close {
    background-color: rgba(18, 19, 20, 1);
    /* --bs-btn-close-bg: url(../../../public/Icon.png); */
}

.modal .modal-header {
    border-bottom: none !important;
    padding: 0px;
}

.modal-dialog {
    height: 100vh;
    display: flex;
    align-items: center;
}


/* =================dashboard header start================= */
/* .login_dtl>div img {
    height: 45px;
    width: auto;
}

.login_dtl h5 {
    font-size: 14px !important;
    margin: 0px;
}

.login_dtl p {
    margin: 0px;
} */

.login_dtl div img {
    /* border-radius: 50%;
    height: 45px;
    width: auto; */

    border-radius: 50%;
    height: 45px;
    width: 45px;
    object-fit: cover;
}

.header .dropdown-menu h5 {
    font-weight: 300;
}

.header {
    padding: 0px 30px;
}

.header .dropdown-menu .icon {
    font-size: 20px;
}

.header .dropdown-menu {
    padding: 20px 0px;
    background: rgba(18, 19, 20, 1);
    box-shadow: 0px 0px 2px 1px #777777;
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    right: 10px;
    margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu[data-bs-popper] {
    left: -125px;
}

/* =================dashboard header end================= */

.togglebtn {
    font-size: 25px;
}

.header .dropdown-toggle::after {
    right: -11px;
    top: 23px;
    position: absolute;
    margin-left: -0.745em;
    vertical-align: 0.255em;
    color: white;
}

.header .fw-normal {
    font-weight: 600 !important;
}

.header .text-info {
    font-size: 14px;
}

:focus-visible {
    outline: none !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.active>a {
    color: rgba(11, 209, 217, 1) !important;
    font-weight: 400;
}

a.nav-link>Link {
    padding: 0px !important;
}



.w_add {
    padding-left: 6px;
}

.h32 {
    height: 32px !important;
    width: auto;
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .navbar-brand img {
        padding: 0px 0px;
        height: 16px;
    }

    .header .btn_uni {
        padding: 6px 5px !important;
        font-size: 10px !important;
    }

    .header .w_add {
        font-size: 12px;
        padding-left: 3px;
    }

    .login_dtl>div img {
        height: 35px;
        width: 35px;
        object-fit: cover;
    }

    .header {
        padding: 0px 0px;
    }
}

.nav-mainbar{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.middle-nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 20px;
}
@media (max-width:998px){
    .middle-nav{
flex-direction: column;
margin: 20px 0px;
align-items: center;
justify-content: center;
    }
    .nav-mainbar{
        flex-direction: column;
    }
}