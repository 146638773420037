.whyus-header{
    height: 100%;
}
.whyus-heading{
    color: rgba(225, 225, 225, 0.50);
font-family: Unbounded;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 73px; /* 182.5% */
letter-spacing: 1.6px;
}

.whyus-heading span{
    background: var(--Brand-Gradient, linear-gradient(90deg, #F907FC 0%, #00F6F6 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Unbounded;
font-size: 60px;
font-style: normal;
font-weight: 900;
line-height: 73px;
letter-spacing: 2.4px;
}

.whyus-points{
    font-size: 16px;
}

.whyus-page-heading{
    color: var(--F-white, #FFF);
font-family: Unbounded;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 41.6px; /* 104% */
letter-spacing: 1.6px;
}

.whyus-button{
   
background: linear-gradient(90deg, rgba(249, 7, 252, 0.05) 0%, rgba(0, 246, 246, 0.05) 100%) padding-box,
linear-gradient(90deg, rgba(249, 7, 252, 0.05) 0%, rgba(0, 246, 246, 0.05) 100%) border-box !important;
border-radius: 8px;
border: 2px solid transparent;
cursor: pointer;
}

.whyus-card-border{
    background: linear-gradient(#000000, #000000) padding-box,
    linear-gradient(142deg, #0E6775 0%, rgba(16, 16, 16, 0) 16%, rgba(16, 16, 16, 0) 89%, #0E6775 100%) border-box !important;
 border-radius: 16px;
 border: 2px solid transparent;
}
.whyus-card1{
   background-image: url('/public/image/whyusc1.svg');
   height: 250px;
  
cursor: pointer;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   object-fit: contain;
   border-radius: 16px;
}
.whyus-card2{
   background-image: url('/public/image/whyusc02.svg');
   height: 250px;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   object-fit: contain;
   border-radius: 14px;
}

.whyus-card1 h5{
    color: #FFF;
text-align: center;
-webkit-text-stroke-width: 1;
-webkit-text-stroke-color: #000;
font-family: Unbounded;
font-size: 18px;
font-style: normal;
margin-bottom: 0px;
font-weight: 400;
line-height: 150%; /* 30px */
letter-spacing: 1px;
}
.whyus-card2 h5{
    color: #FFF;
text-align: center;
-webkit-text-stroke-width: 1;
-webkit-text-stroke-color: #000;
font-family: Unbounded;
font-size: 18px;
margin-bottom: 0px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 30px */
letter-spacing: 1px;
}

@media (max-width:768px){
    .whyus-heading{
       
    font-size: 30px;
    line-height: 40px;
    }
    
    .whyus-heading span{
       
    font-size: 30px;
    line-height: 40px;
    }
    .whyus-page-heading{
        
    font-size: 24px;
   
    line-height: 31.6px; /* 104% */
    letter-spacing: 1.6px;
    }
    
    
}