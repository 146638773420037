@font-face {
  font-family: Montserrat;
  src: url(../src/components/style/fonts/Montserrat/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: Oswald;
  src: url(../src/components/style/fonts/Oswald/Oswald-VariableFont_wght.ttf);
}
@font-face {
  font-family: Unbounded;
  src: url(../src/components/style/fonts/Unbounded/Unbounded-VariableFont_wght.ttf);
}
@font-face {
  font-family: Acumin Pro;
  src: url(../src/components/style/fonts/acumin-pro/Acumin-RPro.otf);
}
@font-face {
  font-family: Proxima Nova;
  src: url(../src/components/style/fonts/proxima-nova/Proxima\ Nova\ Bold.otf);
}
@font-face {
  font-family: Proxima NovaEB;
  src: url(../src/components/style/fonts/proxima-nova/Proxima\ Nova\ Extrabold.otf);
}

body {
  --bs-body-bg: #000;
  overflow-x: hidden;
  --bs-body-color: white;
  scroll-behavior:smooth;
  /* background-image: url(../public/image/back.svg);
  
  background-repeat: no-repeat; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white !important;
  font-family: "Oswald", sans-serif;
}

p {
  color: rgba(215, 215, 215, 1) !important;
  font-weight: 300 !important;
  font-family: "Montserrat", sans-serif;
}

.hd {
  text-align: center;
  font-family: Unbounded;
  margin-bottom: 10px;
}
.hd span{
  background: linear-gradient(90deg, #F40BFC 52.73%, #31EEF2 86.44%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Unbounded;
font-size: 32px;
font-weight: 900;
}

.color-container {
  width: 100%;
  height: 100%;
  background: #000;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  color: white;
  }
  
  .c1 {
    content: "";
    filter: blur(400px);
    height: 200px;
    left: 0px;
    position: absolute;
    top: 5%;
    width: 50%;
    background: #EE10FA;
    z-index: -1;
  }
  
  .c2 {
    content: "";
    filter: blur(140px);
    height: 150px;
    position: absolute;
    width: 150px;
    
    background: #05D6D9;
    top:20%;
    z-index: -1;
    right: 25%;
  }
  .c3 {
    content: "";
    filter: blur(240px);
    height: 200px;
    right: 0px;
    position: absolute;
    top: 25%;
    width: 200px;
    background: #EE10FA;
    z-index: -1;
  }
  
  .c4 {
    content: "";
    filter: blur(240px);
    height: 200px;
    position: absolute;
    width: 250px;
    
    background: #EE10FA;
    top:50%;
    z-index: -1;
    right: 0px;
  }
  .c6 {
    content: "";
    filter: blur(240px);
    height: 200px;
    position: absolute;
    width: 250px;
    
    background: #EE10FA;
    top:70%;
    z-index: -1;
    right: 0px;
  }
  .c5 {
    content: "";
    filter: blur(240px);
    height: 200px;
    position: absolute;
    width: 250px;
    
    background: #05D6D9;
    top:85%;
    z-index: -1;
    left: 0px;
  }
  .c7 {
    content: "";
    filter: blur(240px);
    height: 200px;
    position: absolute;
    width: 250px;
    
    background: #EE10FA;
    top:100%;
    z-index: -1;
    right: 0px;
  }

  .investor-c2 {
    content: "";
    filter: blur(300px);
    height: 300px;
    position: absolute;
    width: 300px;
    
    background: #05D6D9;
    top:50%;
    z-index: -1;
    right: 40%;
  }
 
  .investor-c3 {
    content: "";
    filter: blur(300px);
    height: 400px;
    right: 100px;
    position: absolute;
    top: 100%;
    width: 400px;
    background: #EE10FA;
    z-index: -1;
  }
  .investor-table  .rdt_TableHead{
    border-bottom: 1px solid rgba(215, 215, 215, 0.30);
    border-radius: 16px 16px 0px 0px;
    background: linear-gradient(90deg, rgba(5, 214, 217, 0.30) 0.3%, rgba(249, 7, 252, 0.30) 99.65%);
  }