.banner_footer {
    /* background-image: url(../../../../../public/image/banner.png); */
   
    background-size: cover;
  
    border-radius: 25px;
    background: linear-gradient(#131313, #131313) padding-box,
    linear-gradient(142deg, rgba(0,246,246,1) 0%, rgba(16,16,16,1) 16%, rgba(16,16,16,1) 89%, rgba(0,246,246,1) 100%) border-box;
    border-radius: 40px;
    border: 1px solid transparent;
    position: relative;
    overflow: hidden;
    margin-top: 50px;
}

.banner_footer p {
    color: rgb(189, 189, 189);
}
.footer-banner-img{
    position: absolute;
    right: 0px;
    bottom: 0px;
}
.footer-banner-content{
  z-index: 999;
position: inherit;
}
.side_img {
    position: absolute;
    bottom: -39px;
}

.side_img_wrapper {
    position: relative;
}

.invest_now_btn {
    background: linear-gradient(90deg, #1a242485, #2b1c2b7a) padding-box, linear-gradient(90deg, #05d6d9, #f907fc) border-box;
    border: 1px solid #00000091 !important;
    border-radius: 8px;
    color: #fff;
    font-family: Oswald, sans-serif !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 10px 19px;
    text-decoration: none !important;
    text-transform: uppercase;
    transition: all .9s ease-out;
    transition: .9s;
}

.invest_now_btn:hover{
    box-shadow: 0px 0px 13px 3px #772a91c2;
}

.banner-footer-h4 {
    font-size: 26px;
}
.banner-footer-h2 {
    font-size: 34px;
}
.banner-footer-h2 span {
    background: linear-gradient(90deg, #F907FC 60.95%, #7B80F9 72.05%, #00F6F6 82.92%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Unbounded;
    font-size: 34px;
    font-style: normal;
    font-weight: 900;
}

.mydiv {
    border: 1px solid #ffffff91 !important;
    border-radius: 8px;
    color: #fff;
    font-family: Oswald, sans-serif !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 10px 19px;
    text-decoration: none !important;
    text-transform: uppercase;
    animation: myanimation 5s infinite ease-in-out;
  }
  
  @keyframes myanimation {
    0% {background-color: #05d6d9;}
    25%{background-color:#f907fc;}
    50%{background-color:#0aa7aa;}
    75%{background-color:#9609a8;}
    100% {background-color: #05d6d9;}
  }
@media screen and (min-width: 200px) and (max-width: 768px) {
    .side_img {
        position: initial;
    }
    .footer-banner-img{
        opacity: 0.3 ;
    }
    .banner-footer-h4 {
        font-size: 18px;
        text-align: center;
    }
    .banner-footer-h2 {
        font-size: 15px;
        text-align: center;
    }
    .banner_footer{
        margin-top: 0px;
    }
}

.footer-banner-bg{
    fill: #00F6F6;
    background: #00F6F6;
filter: blur(125px);
position: absolute;
right: 55px;
top: -29px;
width: 226px;
height: 226px;
}