.faq_banner {
    background-image: url(../../../public/image/bannerbackground/banner5.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    background-position: center;
    z-index: 0;
}

.faq .img_wrapper .img-fluid {
    height: 43vh;
    width: auto;
}

.accordion-body,
button.accordion-button.collapsed {
    background: transparent;
    color: white;
}

.faq .accordion-body .line_wrapper {
    display: flex;
    align-items: center;
}

.faq .accordion-body .row .col-1 {
    width: 1% !important;
}

.faq .accordion-body .row .col-1 div {
    height: 100%;
    width: 2px;
    background: linear-gradient(#009ed6, #925eed, #ed10fa);
}

.faq .accordion-body .row .col-11 p {
    margin-bottom: 0px !important;
}

.accordion-button:not(.collapsed) {
    background: transparent;
    color: white;
    box-shadow: none;
}

button:focus:not(:focus-visible) {
    outline: none !important;
}

.accordion-item {
    border: none;
}

.accordion-button:focus {
    box-shadow: none;
}

.faq .accordion-item {
    /* border: 1px solid #313040;
    border-radius: 20px;
    margin: 15px 0px;
    padding: 20px 0px;
    background: rgba(23, 25, 28, 1); */

    border-bottom: 1px solid #313040;
    border-radius: 20px;
    margin: 9px 0px;
    padding: 0px 0px;
    background: rgba(23, 25, 28, 0.884);
}

.accordion-body,
button.accordion-button.collapsed {
    --bs-accordion-body-padding-y: 15px;
}

div#collapseOne {
    background: transparent;
}

.faq .accordion-button {
    font-family: "Montserrat", sans-serif;
}

.own_plan_box>div>div {
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
}

.own_plan_box {
    background-image: url(../../../public/image/footer.png) !important;
    background-size: cover;
    padding: 25px 40px;
    margin: 45px 0px;
    border-radius: 15px;
}

.own_plan_box .row .col-lg-3 {
    display: flex;
    justify-content: end;
    align-items: center;
}

.own_plan_box .row .col-lg-9 h5 {
    font-weight: 300;
}

.own_plan_box .row .col-3 button,
.own_plan_box .row .col-3 button span {
    display: flex;
    align-items: center;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(../../../public/image/toggleIcn/Iconm.png);
    transform: var(--bs-accordion-btn-icon-transform);
    background-size: auto;
}

/* .accordion-button::after {
    background-image: url(../../../public/toggleIcn/Iconp.png);
    background-size: auto;
} */

/* ======= media query ======= */

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .own_plan_box {
        border-radius: 47px;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1100px) {
    .faq .img_wrapper .img-fluid {
        height: auto;
        width: auto;
    }

    .own_plan_box .row .col-lg-3 {
        justify-content: center;
    }
}